// cross-curriculum
import { ReactComponent as SustainabilityInline } from '../icons/cross-curriculum-priorities/sustainability.inline.svg';
import { ReactComponent as AborginalTorresInline } from '../icons/cross-curriculum-priorities/aboriginal-torres.inline.svg';
import { ReactComponent as AsiaAustraliaInline } from '../icons/cross-curriculum-priorities/asia-australia.inline.svg';

// enterprise
import { ReactComponent as BlackDiamondInline } from '../icons/enterprise/black-diamond.inline.svg';
import { ReactComponent as BlueSquareInline } from '../icons/enterprise/blue-square.inline.svg';
import { ReactComponent as GreenCircleInline } from '../icons/enterprise/green-circle.inline.svg';

// general capabilities
import { ReactComponent as InterculturalInline } from '../icons/general-capabilities/intercultural.inline.svg';
import { ReactComponent as CriticalCreativeInline } from '../icons/general-capabilities/critical-creative.inline.svg';
import { ReactComponent as LiteracyInline } from '../icons/general-capabilities/literacy.inline.svg';
import { ReactComponent as ICTInline } from '../icons/general-capabilities/ict.inline.svg';
import { ReactComponent as PersonalAndSocialResponsibililtyInline } from '../icons/general-capabilities/personal-and-social-responsibility.inline.svg';
import { ReactComponent as EthicalUnderstandingInline } from '../icons/general-capabilities/ethical-understanding.inline.svg';
import { ReactComponent as IconNumeracy } from '../icons/general-capabilities/numeracy.svg';

// stimulus/thumbnail types
import { ReactComponent as IconAudio } from '../icons/icon_audio.svg';
import { ReactComponent as IconDocument } from '../icons/icon_document.svg';
import { ReactComponent as EBook } from '../icons/icon_e-book.svg';
import { ReactComponent as IconImage } from '../icons/icon_image.svg';
import { ReactComponent as IconLink } from '../icons/icon_link.svg';
import { ReactComponent as IconVideo } from '../icons/icon_video.svg';
import { ReactComponent as IconWebTool } from '../icons/icon_web-tool.svg';
import { ReactComponent as IconWorksheet } from '../icons/icon_worksheet.svg';
import { ReactComponent as IconInteractive } from '../icons/icon_game.svg';
import { ReactComponent as IconEbookInteractive } from '../icons/icon_ebook_interactive.svg';

// image-picker
import { ReactComponent as IconScaleInline } from '../../../lib/form/image-picker/icon_scale.inline.svg';
import { ReactComponent as IconColourInline } from '../../../lib/form/image-picker/icon_colour.inline.svg';

// miscellaneous
import { ReactComponent as IconStar } from '../icons/icon_star.svg';
import { ReactComponent as IconPlusInline } from '../icons/icon_plus.inline.svg';
import { ReactComponent as IconCheckInline } from '../icons/icon_check.inline.svg';
import { ReactComponent as IconMore } from '../icons/icon_more.svg';
import { ReactComponent as IconCrossInline } from '../icons/icon_cross.inline.svg';
import { ReactComponent as IconDropdownInline } from '../icons/icon_dropdown.inline.svg';
import { ReactComponent as IconTodoNewInline } from '../icons/icon_todo_new.inline.svg';
import { ReactComponent as IconArrowInline } from '../icons/icon_arrow.inline.svg';
import { ReactComponent as IconArrowBoldInline } from '../icons/icon_arrow-bold.inline.svg';
import { ReactComponent as IconMenuInline } from '../icons/icon_menu.inline.svg';
import { ReactComponent as IconPlus2Inline } from '../icons/icon_plus2.inline.svg';
import { ReactComponent as IconPlusInCircle } from '../icons/icon_plus_in_circle.svg';
import { ReactComponent as IconMinusInline } from '../icons/icon_minus.inline.svg';
import { ReactComponent as IconMinusInCircle } from '../icons/icon_minus_in_circle.svg';
import { ReactComponent as IconScrollToTopArrow } from '../icons/icon_scroll-to-top-arrow.svg';
import { ReactComponent as IconTick } from '../icons/icon_tick.svg';
import { ReactComponent as IconDownload } from '../icons/icon_download.svg';
import { ReactComponent as IconShutterstock } from 'approot/shared/shutterstock/icon_shutterstock.svg';
import { ReactComponent as IconInformation } from 'approot/shared/icons/icon_information.svg';
import { ReactComponent as IconSettings } from 'approot/shared/icons/icon_settings.svg';
import { ReactComponent as IconArrowLeft } from 'approot/shared/icons/icon_arrow_left.svg';
import { ReactComponent as IconArrowUp } from 'approot/shared/icons/icon_arrow_up.svg';
import { ReactComponent as IconArrowDown } from 'approot/shared/icons/icon_arrow_down.svg';
import { ReactComponent as IconWavingHand } from 'approot/shared/icons/icon_waving_hand.svg';
import { ReactComponent as IconCreditCardLock } from 'approot/shared/icons/icon_credit_card_lock.svg';
import { ReactComponent as IconAccountingDocument } from 'approot/shared/icons/icon_accounting_document.svg';
import { ReactComponent as IconTrash } from 'approot/shared/icons/icon_trash.svg';
import { ReactComponent as IconExpand } from 'approot/shared/icons/icon_expand.svg';
import { ReactComponent as IconMoveExpand } from 'approot/shared/icons/icon_move_expand.svg';
import { ReactComponent as IconLeave } from 'approot/shared/icons/icon_leave.svg';
import { ReactComponent as IconRestore } from 'approot/shared/icons/icon_restore.svg';
import { ReactComponent as IconEye } from 'approot/shared/icons/icon_eye.svg';
import { ReactComponent as IconEyeHide } from 'approot/shared/icons/icon_eye_hide.svg';
import { ReactComponent as IconExclamationMarkCircle } from 'approot/shared/icons/icon_exclamation_mark_circle.svg';
import { ReactComponent as IconLock } from 'approot/shared/icons/icon_shield_lock.svg';
import { ReactComponent as IconAlertCircle } from 'approot/shared/icons/icon_alert.svg';
import { ReactComponent as IconTip } from 'approot/shared/icons/icon_light_bulb.svg';

import { ReactComponent as IconNotification } from 'approot/shared/icons/icon-notification.svg';
import { ReactComponent as IconSwitchOn } from 'approot/shared/switch/switch-on.svg';
import { ReactComponent as IconSwitchOff } from 'approot/shared/switch/switch-off.svg';
import { ReactComponent as IconUpload } from 'approot/shared/icons/icon_upload.svg';
import { ReactComponent as IconFileImage } from 'approot/shared/icons/icon_file_image.svg';
import { ReactComponent as IconSearch } from 'approot/shared/icons/icon_search.svg';
import { ReactComponent as IconAudioOff } from '../icons/icon_audio_off.svg';
import { ReactComponent as IconNotAllowed } from '../icons/icon_not_allowed.svg';
import { ReactComponent as IconQuestionMark } from '../icons/icon_question_mark.svg';
import { ReactComponent as IconDoubleArrows } from '../icons/icon_double_arrows.svg';

type AnyObject = {
  [key: string]: any;
};

export const inlineSvgMapping = {
  // cross-curriculum
  sustainability: SustainabilityInline,
  'aboriginal-torres': AborginalTorresInline,
  'asia-australia': AsiaAustraliaInline,

  // enterprise
  blackdiamond: BlackDiamondInline,
  bluesquare: BlueSquareInline,
  greencircle: GreenCircleInline,

  // general capabilities
  'critical-creative': CriticalCreativeInline,
  'ethical-understanding': EthicalUnderstandingInline,
  ict: ICTInline,
  intercultural: InterculturalInline,
  literacy: LiteracyInline,
  numeracy: IconNumeracy,
  'personal-and-social-responsibility': PersonalAndSocialResponsibililtyInline,

  // stimulus/... content types
  audio: IconAudio,
  document: IconDocument,
  'e-book': EBook,
  'e-book-interactive': IconEbookInteractive,
  book: EBook,
  'guided-research': IconSearch,
  image: IconImage,
  link: IconLink,
  video: IconVideo,
  'web-tool': IconWebTool,
  worksheet: IconWorksheet,
  interactive: IconInteractive,

  // image-picker
  icon_scale: IconScaleInline,
  icon_colour: IconColourInline,

  // miscellaneous
  icon_star: IconStar,
  icon_plus: IconPlusInline,
  icon_check: IconCheckInline,
  icon_more: IconMore,
  icon_dropdown: IconDropdownInline,
  icon_todo_new: IconTodoNewInline,
  icon_arrow: IconArrowInline,
  icon_arrow_bold: IconArrowBoldInline,
  icon_menu: IconMenuInline,
  icon_plus2: IconPlus2Inline,
  icon_plus_in_circle: IconPlusInCircle,
  icon_minus: IconMinusInline,
  icon_minus_in_circle: IconMinusInCircle,
  icon_scrolltop: IconScrollToTopArrow,
  icon_cross: IconCrossInline,
  icon_shutterstock: IconShutterstock,
  icon_tick: IconTick,
  icon_download: IconDownload,
  icon_information: IconInformation,
  icon_settings: IconSettings,
  icon_arrow_left: IconArrowLeft,
  icon_arrow_up: IconArrowUp,
  icon_arrow_down: IconArrowDown,
  icon_waving_hand: IconWavingHand,
  icon_credit_card_lock: IconCreditCardLock,
  icon_accounting_document: IconAccountingDocument,
  icon_trash: IconTrash,
  icon_expand: IconExpand,
  icon_move_expand: IconMoveExpand,
  icon_leave: IconLeave,
  icon_restore: IconRestore,
  icon_eye: IconEye,
  icon_eye_hide: IconEyeHide,
  icon_exclamation_mark: IconExclamationMarkCircle,
  icon_upload: IconUpload,
  icon_file_image: IconFileImage,
  icon_notification: IconNotification,
  icon_switch_on: IconSwitchOn,
  icon_switch_off: IconSwitchOff,
  icon_tip: IconTip,
  icon_alert: IconAlertCircle,
  icon_lock: IconLock,
  icon_audio_off: IconAudioOff,
  icon_not_allowed: IconNotAllowed,
  icon_question_mark: IconQuestionMark,
  icon_double_arrows: IconDoubleArrows,
};

export type InlineSvgIds = keyof typeof inlineSvgMapping;

export type Props = {
  id: InlineSvgIds;
  // you might need to rebuild the inline SVGs by running `yarn run build-svg`
  // to embed them in the index.html
  width?: string | number;
  height?: string | number;
  className?: string;
  label?: string;
  $rotate?: number;
};

const InlineSvg = (props: Props) => {
  const svgProps: AnyObject = {};
  //Setting a fallback small width/height (where not passed througoh as a prop),
  //so that we don't see massive SVGs before the CSS Loads.
  //Note, if we ever want to use an SVG that should display at its natural size,
  //we might want to consider a new prop for that (e.g. 'naturalSize')
  props.width ? (svgProps.width = props.width) : (svgProps.width = 16);
  props.height ? (svgProps.height = props.height) : (svgProps.height = 16);
  if (props.className) svgProps.className = `${props.id} ${props.className}`;
  if (props.label) svgProps['aria-label'] = props.label;
  const Svg = inlineSvgMapping[props.id];
  if (!Svg) {
    console.error(`Received invalid id prop "${props.id}" to inline-svg.js`);
    return null;
  }
  return (
    <Svg
      {...svgProps}
      focusable="false"
      style={
        props.$rotate
          ? {
              transform: `rotate(${props.$rotate}deg)`,
            }
          : {}
      }
    />
  );
};

export default InlineSvg;
